/* #about {
  background-color: #edf2f7;
} */

.wallpaper {
  background: url("./Assets/wallpaper2.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-attachment: scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
/* .wallpaper {
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  background: #c53030;
  position: relative;
} */
/* .wallpaper:after {
  background: #edf2f7;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: -1px;
  height: 20px;
  border-radius: 50% 50% 0 0;
} */

@media only screen and (max-width: 1200px) {
  .wallpaper {
    background: url("./Assets/wallpaper.jpeg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-attachment: scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    max-width: 1200px;
    margin: auto;
  }
}

#about {
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.footerBackground {
  position: relative;
  background: #09f;
  color: #fff;
  text-align: center;
  overflow: hidden;
}
